<template>
    <component
        :is="resolvedElement"
        :data-label-position="labelPosition"
        class="base-arrow-link"
    >
        <slot /><span class="base-arrow-link__arrow" />
    </component>
</template>

<script setup>
const props = defineProps({
    element: {
        type: [String, Object],
        default: undefined
    },
    labelPosition: {
        type: String,
        default: 'left',
        validator: (value) => ['left', 'right'].includes(value)
    }
});

const resolvedElement = computed(() => {
    return !props.element ? resolveComponent('CraftLink') : props.element;
});
</script>

<style lang="less">
.base-arrow-link {
    .typography-text();
    .link-clean();

    display: inline-flex;
    align-items: center;
    line-height: 1;
    gap: var(--spacing-2xs);

    &[href] {
        text-decoration: none;
        color: currentColor;

        &:hover,
        &:visited {
            color: currentColor;
        }
    }

    &:focus {
        outline: none;
    }

    a[href]:active &,
    &:active {
        opacity: .6;
    }
}

[data-label-position='right'] {
    flex-direction: row-reverse;
}

.base-arrow-link--dark {
    color: var(--color-darkest);
}

.base-arrow-link__arrow {
    height: 1px;
    width: 1.5em;
    margin-bottom: .2em;
    display: block;
    background-color: currentColor;
    position: relative;
    transition: all .5s cubic-bezier(.4, 0, .2, 1);

    &:before, &:after {
        content: '';
        width: 1px;
        height: 1px;
        display: block;
        background-color: currentColor;
        position: absolute;
        top: 0;
        right: 0;
        transform-origin: center right;
        transition: width .5s cubic-bezier(.5, 0, .2, 1), opacity .5s cubic-bezier(.5, 0, .2, 1);
        opacity: 0;
    }

    &:before {
        transform: rotate(45deg);
    }

    &:after {
        transform: rotate(-45deg);
    }

    a[href]:hover &,
    a[href]:focus &,
    a[href]:active &,
    :hover > &,
    :focus > &,
    :active > & {
        width: 4em;

        &:before,
        &:after {
            width: .5em;
            opacity: 1;
        }
    }
}
</style>
